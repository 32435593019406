<template>
  <div class="w100w">
      <div class="mybetwrap">
        <ul class="tabs">
          <li class="tab-link" :class="{'current': type == 'issued'}" data-tab="tab-1" @click="type = 'issued'"><span>쿠폰발급내역</span></li>
          <li class="tab-link" :class="{'current': type == 'gift'}" data-tab="tab-2" @click="type = 'gift'"><span>쿠폰선물내역</span></li>
        </ul>
        <div id="tab-1" class="tab-content" :class="{'current': type == 'issued'}" v-if="type=='issued'">
          <ul class="couponnumlist">
            <li>사용가능 : <span class="rdc">32</span>장</li>
            <li>사용완료 : <span class="blc">8</span>장</li>
            <li>기간만료 : <span class="grc">0</span>장</li>
            <li>회수된쿠폰 : <span class="gnc">0</span>장</li>
          </ul>
          <div class="board">
            <ul class="boardw first">
              <li class="">쿠폰명</li>
              <li class="">쿠폰금액</li>
              <li class="">보낸회원아이디</li>
              <li class="">처리상태</li>
              <li class="">발급일</li>
              <li class="">만료일</li>
            </ul>
            <ul class="boardw">
              <li class="">테스트쿠폰</li>
              <li class="rdc">10,000</li>
              <li class="">eric</li>
              <li class="rdc">사용가능</li>
              <li class="">2021-06-29</li>
              <li class="">2021-07-29</li>
            </ul>
            <ul class="boardw">
              <li class="">테스트쿠폰</li>
              <li class="rdc">10,000</li>
              <li class="">eric</li>
              <li class="blc">사용완료</li>
              <li class="">2021-06-29</li>
              <li class="">2021-07-29</li>
            </ul>
            <ul class="boardw">
              <li class="">테스트쿠폰</li>
              <li class="rdc">10,000</li>
              <li class="">eric</li>
              <li class="grc">기간만료</li>
              <li class="">2021-06-29</li>
              <li class="">2021-07-29</li>
            </ul>
            <ul class="boardw">
              <li class="">테스트쿠폰</li>
              <li class="rdc">10,000</li>
              <li class="">eric</li>
              <li class="gnc">회수쿠폰</li>
              <li class="">2021-06-29</li>
              <li class="">2021-07-29</li>
            </ul>
          </div>

          <pagination :className="'pt60 mt20'" />

        </div>

        <div id="tab-2" class="tab-content"  :class="{'current': type == 'gift'}" v-if="type=='gift'">
          <ul class="couponnumlist">
            <li>사용가능 : <span class="rdc">32</span>장</li>
            <li>사용완료 : <span class="blc">8</span>장</li>
            <li>기간만료 : <span class="grc">0</span>장</li>
            <li>회수된쿠폰 : <span class="gnc">0</span>장</li>
          </ul>
          <div class="board">
            <ul class="boardw first">
              <li class="">쿠폰명</li>
              <li class="">쿠폰금액</li>
              <li class="">받는회원아이디</li>
              <li class="">보낸일시</li>
              <li class="">처리상태</li>
              <li class="">발급일</li>
              <li class="">만료일</li>
            </ul>
            <ul class="boardw">
              <li class="">테스트쿠폰</li>
              <li class="rdc">10,000</li>
              <li class="">eric</li>
              <li class="">2021-07-29 15:45:41</li>
              <li class="ppc">선물완료</li>
              <li class="">2021-06-29</li>
              <li class="">2021-07-29</li>
            </ul>
            <ul class="boardw">
              <li class="">테스트쿠폰</li>
              <li class="rdc">10,000</li>
              <li class="">eric</li>
              <li class="">2021-07-29 15:45:41</li>
              <li class="ppc">선물완료</li>
              <li class="">2021-06-29</li>
              <li class="">2021-07-29</li>
            </ul>
            <ul class="boardw">
              <li class="">테스트쿠폰</li>
              <li class="rdc">10,000</li>
              <li class="">eric</li>
              <li class="">2021-07-29 15:45:41</li>
              <li class="ppc">선물완료</li>
              <li class="">2021-06-29</li>
              <li class="">2021-07-29</li>
            </ul>
            <ul class="boardw">
              <li class="">테스트쿠폰</li>
              <li class="rdc">10,000</li>
              <li class="">eric</li>
              <li class="">2021-07-29 15:45:41</li>
              <li class="ppc">선물완료</li>
              <li class="">2021-06-29</li>
              <li class="">2021-07-29</li>
            </ul>
          </div>

          <pagination :className="'pt60 mt20'" />

        </div>
      </div>

    </div>

</template>

<script>
import Pagination from '@/components/ui/Pagination'

export default {
  name: 'couponList',
  components: {
    Pagination
  },
  data () {
    return {
      type: 'issued'
    }
  }
}
</script>
<style scoped>
.mybetwrap .tabs li {font-size: 16px !important;padding: 11px 0 !important;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
